import {Snackbar} from "@mui/material";

interface scrapeStatusProps {
    open: boolean;
    message: string;
}

const ScrapeStatus = ({open, message}: scrapeStatusProps) => {
    return (
        <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            open={open}
            message={message}
            autoHideDuration={3000}
            ContentProps={{
                sx: {
                    display: "block",
                    textAlign: "center",
                },
            }}
        />
    );
};

export default ScrapeStatus;