import {Box} from "@mui/material";
import NavDrawer from "./nav/NavDrawer";
import Dashboard from "./dashboard/Dashboard";
import {useAuthContext} from "../contexts/AuthContext";
import Snackbar from "./snackbar/Snackbar";
import Auth from "./auth/Auth";
import {ReactNode} from "react";

const App = () => {
    const user = useAuthContext();

    let body: ReactNode;
    if (user === null) {
        body = <>
            <Auth/>
            <Snackbar/>
        </>;
    } else {
        body = <>
            <NavDrawer/>
            <Dashboard/>
            <Snackbar/>
        </>;
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: 0,
            }}
        >
            {body}
        </Box>
    );
};

export default App;
