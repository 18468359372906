import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AddSourceModalOpenState {
  open: boolean;
}

const initialState: AddSourceModalOpenState = { open: false };

const addSourceModalOpenSlice = createSlice({
  name: "addSourceModalOpen",
  initialState,
  reducers: {
    setAddSourceModalOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
  },
});

export const { setAddSourceModalOpen } = addSourceModalOpenSlice.actions;
export default addSourceModalOpenSlice.reducer;
