import ModalDialog from "../../../../shared/ModalDialog";
import * as React from "react";
import Form, {Field} from "../../../../shared/Form";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../state/store";
import {FieldValues} from "react-hook-form";
import {
    Source,
    SuggestionSourceUpdate,
    usePutV1SourcesDocumentBySourceIdMutation,
    usePutV1SourcesSuggestionBySourceIdMutation,
} from "../../../../state/layerApi";
import {setEditSourceModalOpen} from "../../../../state/sources/editSourceModalOpenSlice";

export default function EditSourceModal({source}: { source: Source }) {
    const dispatch = useDispatch<AppDispatch>();
    // TODO: convert to specific selector
    const editSourceModalOpen = useSelector(
        (state: RootState) => state.editSourceModalOpen.open
    );

    const [putDocumentSource, {isLoading: isDocumentLoading}] = usePutV1SourcesDocumentBySourceIdMutation();
    const [putSuggestionSource, {isLoading: isSuggestionLoading}] = usePutV1SourcesSuggestionBySourceIdMutation();

    const handleClose = () => dispatch(setEditSourceModalOpen({
        open: false,
        selectedRowId: null,
    }));

    const handleFormSubmit = (values: FieldValues) => {
        const sourceType = source.type;
        switch (sourceType) {
            case 'document':
                const formData = new FormData();
                if (values.name)
                    formData.set('name', values.name);
                if (values.file)
                    formData.set('file', values.file);

                putDocumentSource({
                    sourceId: source.id,
                    // @ts-ignore
                    documentSourceUpdate: formData,
                }).then(() => handleClose());
                break;
            case 'suggestion':
                const suggestionSourceUpdate: SuggestionSourceUpdate = {};
                if (values.name)
                    suggestionSourceUpdate.name = values.name;
                if (values.question)
                    suggestionSourceUpdate.question = values.question;
                if (values.answer)
                    suggestionSourceUpdate.answer = values.answer;

                putSuggestionSource({
                    sourceId: source.id,
                    suggestionSourceUpdate: suggestionSourceUpdate
                }).then(() => handleClose());
                break;
        }
    };

    // TODO: Front end validation? Or handle error messages from back end?
    // TODO: add collections
    const fields: Field[] = [
        {
            fieldName: "name",
            fieldLabel: "Name",
            fieldType: "text",
            fieldRequired: false,
            fieldHelperText: "Source names must be unique",
            fieldDefaultValue: source.name,
        },
    ];

    switch (source.type) {
        case 'document':
            // TODO: somehow track if this changes
            fields.push({
                fieldName: "file",
                fieldLabel: "Document",
                fieldType: "file",
                fieldRequired: false,
            });
            break;
        case 'suggestion':
            fields.push(
                {
                    fieldName: "question",
                    fieldLabel: "Question",
                    fieldType: "text",
                    fieldRequired: false,
                    fieldDefaultValue: source.question,
                },
                {
                    fieldName: "answer",
                    fieldLabel: "Answer",
                    fieldType: "text",
                    fieldRequired: false,
                    fieldDefaultValue: source.answer,
                }
            );
            break;
    }

    return (
        <>
            <ModalDialog
                label="Edit Source"
                modalOpen={editSourceModalOpen}
                handleClose={handleClose}
            >
                <Form
                    fields={fields}
                    handleFormSubmit={handleFormSubmit}
                    submitButtonLabel="Save Source"
                    submitButtonLoading={[isDocumentLoading, isSuggestionLoading].some(v => v)}
                />
            </ModalDialog>
        </>
    );
};