import {Box, Button, CircularProgress, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../state/store";
import {
    useGetV1SourcesWebsiteCrawlProgressByJobIdQuery,
} from "../../../state/layerApi";

interface crawlStatusProps {
    id: string;
    onError: () => void;
    onDoneButton: () => void;
    onCloseButton: () => void;
}

const CrawlStatus = (props: crawlStatusProps) => {
    const darkMode = useSelector((state: RootState) => state.theme.darkMode);
    const [pollingInterval, setPollingInterval] = useState(5000);
    const {data: progress, error} = useGetV1SourcesWebsiteCrawlProgressByJobIdQuery(
        {
            jobId: props.id,
        },
        {
            pollingInterval: pollingInterval,
            skipPollingIfUnfocused: true,
        },
    );

    const [message, setMessage] = useState<string>("Crawl Queued...");
    const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (progress === undefined) return;

        if (progress.done) {
            setPollingInterval(0);
            setMessage("Crawl Complete!");
        } else if (progress.successful_urls.length > 0) {
            setPollingInterval(2000);
            const hostname = new URL(progress.successful_urls[0] as string).hostname;
            const finished = progress.successful_urls.length;
            const total = finished + progress.to_visit_urls.length;
            setMessage(`Crawling ${hostname}`);
            setLoadingMessage(`${finished}/${total}`);
        } else if (progress.to_visit_urls.length > 0) {
            setPollingInterval(2000);
            const hostname = new URL(progress.to_visit_urls[0] as string).hostname;
            const finished = progress.successful_urls.length;
            const total = finished + progress.to_visit_urls.length;
            setMessage(`Crawling ${hostname}`);
            setLoadingMessage(`${finished}/${total}`);
        } else {
            setMessage("Crawl Queued...");
        }
    }, [progress, setMessage, setLoadingMessage, setPollingInterval]);

    const onError = props.onError;

    useEffect(() => {
        if (error === undefined) return;
        console.error(JSON.stringify(error));
        onError();
    }, [error, onError]);

    if (progress === undefined) {
        return <></>
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: 3,
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: 1,
                px: 2,
                py: 1,
                bgcolor: darkMode ? "#fff" : "grey.800",
            }}
        >
            <Typography variant="body2">{message}</Typography>
            <Box sx={{display: "flex", gap: 1, alignItems: "center"}}>
                {!progress.done && (
                    <Box sx={{display: "flex", gap: 2, alignItems: "center"}}>
                        {loadingMessage !== undefined && (
                            <Typography variant="body2">
                                {loadingMessage}
                            </Typography>
                        )}
                        <CircularProgress size={20}/>
                    </Box>
                )}
                {progress.done && (
                    <>
                        <Button
                            variant="text"
                            size="small"
                            sx={{p: 1}}
                            onClick={props.onDoneButton}
                        >
                            Select Sources to Add
                        </Button>
                        <IconButton onClick={props.onCloseButton}>
                            <CloseIcon
                                sx={{color: darkMode ? "#333" : "#fff"}}
                            />
                        </IconButton>
                    </>
                )}
            </Box>
        </Box>
    );
}

export default CrawlStatus;