import * as React from "react";
import {useLocation} from "react-router-dom";
import Signup from "./Signup";
import Login from "./Login";

const Auth = () => {
    const location = useLocation();
    const [showSignUp, setShowSignUp] = React.useState<boolean>(location.pathname.startsWith("/signup"));

    React.useEffect(() => {
        setShowSignUp(location.pathname.startsWith("/signup"));
    }, [location, setShowSignUp]);

    React.useEffect(() => {
        document.title = showSignUp ? "Layer - Sign Up" : "Layer - Log In";
    }, [showSignUp]);

    return showSignUp ? <Signup /> : <Login />;
};

export default Auth;