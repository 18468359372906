import { IPageTemplate } from "./PageTemplate";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Sources from "../components/dashboard/sources/Sources";
import StoreIcon from "@mui/icons-material/Store";
import MarketplaceListing from "../components/dashboard/MarketplaceListing";
import React from "react";
import Analytics from "../components/dashboard/analytics/Analytics";
import BarChartIcon from "@mui/icons-material/BarChart";

const pageProps: (IPageTemplate & { path: string })[] = [
  {
    name: "Sources",
    description:
      "Add & manage the sources Integration Expert references to assist developers integrating Plaid.",
    icon: <UploadFileIcon />,
    children: <Sources />,
    path: "/sources",
  },
  {
    name: "Analytics",
    description:
      "Get analytics on how developers are using your Integration Expert.",
    icon: <BarChartIcon />,
    children: <Analytics />,
    path: "/analytics",
  },
  {
    name: "Marketplace Listing",
    description:
      "Adjust how your Integration Expert extension will show up in VSCode’s marketplace.",
    icon: <StoreIcon />,
    children: <MarketplaceListing />,
    path: "/marketplace-listing",
  },
];

export default pageProps;
