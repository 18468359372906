import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import GoogleLogo from "../../assets/google-logo.svg";
import LayerLogoDark from "../../assets/LayerLogoDark.svg";
import DashboardPreview from "../../assets/DashboardPreview.png";
import React, {useState} from "react";
import {signInWithEmailAndPassword, signInWithPopup} from "firebase/auth";
import {auth, provider} from "../../clients/firebase";
import EmailAndPasswordForm from "./EmailAndPasswordForm";
import {useSnackbarContext} from "../../contexts/SnackbarContext";

const Login = () => {
    const {addMessage} = useSnackbarContext();
    const navigate = useNavigate();
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    async function handleLogIn(email: string, password: string) {
        setSubmitButtonLoading(true);
        signInWithEmailAndPassword(auth, email, password)
            .catch((error) => {
                if (
                    error.code === "auth/user-not-found" ||
                    error.code === "auth/wrong-password"
                ) {
                    addMessage("Incorrect email or password.", "error", 60);
                } else {
                    console.error(error);
                }
            })
            .finally(() => {
                setSubmitButtonLoading(false);
            });
    }

    async function handleGoogleSignUp() {
        signInWithPopup(auth, provider)
            .catch((error) => {
                console.error(error);
                if (error.status === 401) {
                    addMessage("Account not found. Please sign up first.", "error", 60);
                }
            })
            .finally(() => {
                setSubmitButtonLoading(false);
            });
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    position: "absolute",
                    top: "32px",
                    left: "32px",
                }}
            >
                <img src={LayerLogoDark} alt={"Layer Logo"} style={{width: "40px"}}/>
                <Box sx={{display: "flex", flexDirection: "column", gap: "2px"}}>
                    <Typography variant="h1" sx={{fontSize: "1.1rem"}}>
                        Layer
                    </Typography>
                    <Typography variant="h1" sx={{fontSize: "1rem", fontWeight: "300"}}>
                        Integration Expert
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100vw",
                    height: "100vh",
                    alignItems: "stretch",
                }}
            >
                <Box
                    sx={{
                        minWidth: "60%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "500px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "stretch",
                            gap: 3,
                        }}
                    >
                        <Typography variant="h2">Log In</Typography>
                        <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                            <EmailAndPasswordForm
                                submitLabel={"Log In"}
                                loading={submitButtonLoading}
                                submit={handleLogIn}
                            />
                            <Button
                                variant="contained"
                                onClick={handleGoogleSignUp}
                                sx={{
                                    elevation: 0,
                                    bgcolor: "white",
                                    color: "#333333",
                                    border: 1,
                                    borderColor: "divider",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 2,
                                    ":hover": {
                                        bgcolor: "#F3F3F3",
                                    },
                                }}
                            >
                                <img
                                    src={GoogleLogo}
                                    alt="Google Logo"
                                    style={{width: "24px", height: "24px"}}
                                />
                                Log in with Google
                            </Button>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 1,
                                    flexGrow: 1,
                                }}
                            >
                                <Typography sx={{textAlign: "center", color: "#757575"}}>
                                    Don't have an account yet?
                                </Typography>
                                <Button
                                    variant="text"
                                    onClick={() => navigate("/signup")}
                                    sx={{py: 0, px: "2px", minWidth: 0}}
                                >
                                    Sign up
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 1,
                                    flexGrow: 1,
                                }}
                            >
                                <Button
                                    variant="text"
                                    onClick={() => navigate("/reset-password")}
                                    sx={{py: 0, px: "2px", minWidth: 0}}
                                >
                                    Forgot Password?
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        pl: "5%",
                        gap: 8,
                        justifyContent: "end",
                        alignItems: "start",
                        bgcolor: "#7C7799",
                        color: "white",
                        boxSizing: "border-box",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            pr: "5%",
                        }}
                    >
                        <Typography variant="h2">
                            Make your API easier to integrate
                        </Typography>
                        <Typography>
                            Just upload your documentation and reference materials and we’ll
                            deploy your Integration Expert to VS Code, Github Copilot, and
                            more.
                        </Typography>
                    </Box>
                    <img
                        src={DashboardPreview}
                        alt="Layer Management Platform Dashboard"
                        style={{
                            flexGrow: 1,
                            width: "100%",
                            maxHeight: "60%",
                            objectFit: "cover",
                            objectPosition: "top left",
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default Login;
