import * as React from "react";
import { Box, Button } from "@mui/material";
import {
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDeleteV1SourcesBySourceIdMutation } from "../../../../state/layerApi";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../state/store";
import { setAddSourceModalOpen } from "../../../../state/sources/addSourceModalOpenSlice";
import { setSnackbarOpen } from "../../../../state/snackbarSlice";
import DeleteConfirmationModal from "../../../../shared/DeleteConfirmationModal";

interface ISourceGridToolbar {
  rowSelectionModel: GridRowSelectionModel;
}

const SourceGridToolbar = ({ rowSelectionModel }: ISourceGridToolbar) => {
  const dispatch = useDispatch<AppDispatch>();
  const [deleteSource, { isLoading }] = useDeleteV1SourcesBySourceIdMutation();

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    React.useState(false);

  const handleOpenAddSourceModal = () => {
    dispatch(setAddSourceModalOpen(true));
  };

  const handleDeleteSourceRows = () => {
    rowSelectionModel.forEach((id, index) => {
      if (index === rowSelectionModel.length - 1) {
        deleteSource({ sourceId: id as number }).then(() => {
          dispatch(
            setSnackbarOpen({
              open: true,
              message:
                rowSelectionModel.length > 1
                  ? "Sources deleted"
                  : "Source deleted",
            }),
          );
        });
      } else {
        deleteSource({ sourceId: id as number });
      }
    });
  };

  const deleteSelectedSourceRows = () => {
    setDeleteConfirmationModalOpen(true);
  };

  return (
    // TODO: Adjust these styles
    <>
      <GridToolbarContainer>
        <Box
          sx={{
            display: "flex",
            gap: 4,
            alignItems: "center",
            p: "12px",
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <GridToolbarQuickFilter sx={{ flexGrow: 1 }} variant="filled" />
          <Box
            sx={{
              display: "flex",
              gap: "12px",
              height: "48px",
            }}
          >
            <Button
              variant="outlined"
              disabled={rowSelectionModel.length === 0 || isLoading}
              color="error"
              onClick={deleteSelectedSourceRows}
              sx={{ minWidth: 0, width: "56px", height: "48px" }}
            >
              <DeleteIcon />
            </Button>
            <Button
              variant="contained"
              onClick={handleOpenAddSourceModal}
              sx={{ minWidth: 0, width: "56px", height: "48px" }}
            >
              <AddIcon />
            </Button>
          </Box>
        </Box>
      </GridToolbarContainer>
      <DeleteConfirmationModal
        modalOpen={deleteConfirmationModalOpen}
        itemName={
          rowSelectionModel.length > 1
            ? rowSelectionModel.length + " sources"
            : "1 source"
        }
        setOpen={setDeleteConfirmationModalOpen}
        handleDelete={handleDeleteSourceRows}
      />
    </>
  );
};

export default SourceGridToolbar;
