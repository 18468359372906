import * as React from "react";
import {Field, Form} from "../../shared/Form";
import {FieldValues} from "react-hook-form";
import {useGetV1CustomersCompanyByCompanyNameQuery} from "../../state/layerApi";
import {Box} from "@mui/material";
import {useSnackbarContext} from "../../contexts/SnackbarContext";

interface CompanyNameFormProps {
    setCompanyName: (name: string) => void;
}

const CompanyNameForm = ({setCompanyName}: CompanyNameFormProps) => {
    const {addMessage} = useSnackbarContext();
    const [localCompanyName, setLocalCompanyName] = React.useState("");
    const [submissionAttempt, setSubmissionAttempt] = React.useState(0);

    const {
        isLoading: isGetCustomerByNameLoading,
        error: getCustomerByNameError,
    } = useGetV1CustomersCompanyByCompanyNameQuery(
        {
            companyName: localCompanyName,
        },
        {skip: localCompanyName === ""},
    );

    const companyNameFields: Field[] = [
        {
            fieldName: "companyName",
            fieldLabel: "Company Name",
            fieldRequired: true,
            fieldType: "text",
        },
    ];

    function handleNext({companyName}: FieldValues) {
        if (!companyName) return;
        setLocalCompanyName(companyName);
        setSubmissionAttempt((prevAttempt) => prevAttempt + 1);
    }

    React.useEffect(() => {
        if (getCustomerByNameError) {
            if (
                "status" in getCustomerByNameError &&
                getCustomerByNameError.status === 404
            ) {
                setCompanyName(localCompanyName);
            }
        } else if (localCompanyName !== "" && !isGetCustomerByNameLoading) {
            addMessage("Company name already exists.", "error", 60);
        }
    }, [
        getCustomerByNameError,
        localCompanyName,
        setCompanyName,
        isGetCustomerByNameLoading,
        submissionAttempt,
        addMessage,
    ]);

    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
            <Form
                fields={companyNameFields}
                submitButtonLabel="Next"
                submitButtonLoading={isGetCustomerByNameLoading}
                handleFormSubmit={handleNext}
            />
        </Box>
    );
};

export default CompanyNameForm;
