import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ThemeState {
  darkMode: boolean;
}

const initialState: ThemeState = { darkMode: true };

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setDarkMode(state, action: PayloadAction<boolean>) {
      state.darkMode = action.payload;
      document.documentElement.setAttribute(
        "data-color-mode",
        action.payload ? "dark" : "light",
      );
    },
  },
});

export const { setDarkMode } = themeSlice.actions;
export default themeSlice.reducer;
