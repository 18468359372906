import React from "react";
import {
  Box,
  createTheme,
  ThemeProvider as MuiThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const layerTheme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: "#7B65FF",
        100: "rgba(123, 101, 255, .20)",
        200: "rgba(123, 101, 255, .30)",
        300: "rgba(123, 101, 255, .50)",
      },
      secondary: {
        main: "#3794FF",
      },
      info: {
        main: "#7B65FF",
        light: "rgba(123, 101, 255, .80)",
        dark: "#1f145c",
      },
      grey: {
        100: darkMode ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.05)",
        200: darkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
        300: darkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
        400: darkMode ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.3)",
        500: darkMode ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)",
        800: darkMode ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.8)",
      },
      background: {
        paper: darkMode ? "#1a1a1a" : "#fff",
      },
    },
    typography: {
      fontFamily: "'Manrope', sans-serif",
      h1: {
        fontSize: "1.8rem",
        fontWeight: 600,
      },
      h2: {
        fontSize: "1.5rem",
        fontWeight: 600,
      },
      h3: {
        fontSize: "1.25rem",
        fontWeight: 600,
      },
      h4: {
        fontSize: "1rem",
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: "1.1rem",
        fontWeight: 400,
      },
      button: {
        fontWeight: 600,
        fontSize: "1rem",
        textTransform: "none",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            padding: "12px",
          },
        },
      },
    },
  });
  const sufficientScreenSize = useMediaQuery(layerTheme.breakpoints.up("sm"));

  if (!sufficientScreenSize) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Typography variant="h6" sx={{ textAlign: "center", p: 4 }}>
          Please use a larger screen to view this application. Mobile support
          coming soon.
        </Typography>
      </Box>
    );
  }

  return <MuiThemeProvider theme={layerTheme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
