import {
    useContext,
    createContext,
    ReactNode,
    useState,
    useEffect,
} from "react";
import {auth} from "../clients/firebase";
import {User, onAuthStateChanged, signOut} from "firebase/auth";
import {useSnackbarContext} from "./SnackbarContext";
import {Box} from "@mui/material";
import {store} from "../state/store";
import {layerApi} from "../state/layerApi";

const AuthContext = createContext<User | null>(auth.currentUser);

export function AuthContextProvider({children}: { children: ReactNode }) {
    const authContext = useAuthContextSetup();
    if (authContext === undefined) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <p>Loading...</p>;
            </Box>
        );
    }
    return (
        <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
    );
}

export function useAuthContext() {
    return useContext(AuthContext);
}

function useAuthContextSetup() {
    const [user, setUser] = useState<User | null | undefined>(undefined);
    const {addMessage} = useSnackbarContext();

    const fetchCustomer = async (token: string) => {
        return fetch(`${process.env.REACT_APP_LAYER_URL}v1/customers/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Firebase-Auth": token,
            },
        });
    };

    useEffect(() => {
        const cleanup = onAuthStateChanged(auth, (user: User | null) => {
            if (user === null) {
                setUser(user);
                return;
            }

            user
                .getIdToken()
                .then(fetchCustomer)
                .then((response) => {
                    if (response.status === 200) {
                        setUser(user);
                    } else {
                        addMessage("Account not found. Please sign up first.", "error", 60);
                        return signOut(auth);
                    }
                })
                .then(() => {
                    store.dispatch(layerApi.util.resetApiState());
                })
                .catch((err) => {
                    console.error(err);
                });
        });

        return () => cleanup();
    }, [addMessage]);

    return user;
}
