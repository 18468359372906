import * as React from 'react';
import {Field, Form} from "../../shared/Form";
import {FieldValues} from "react-hook-form";

interface EmailAndPasswordFormProps {
    submitLabel: string;
    loading: boolean;
    submit: (email: string, password: string) => void;
}

const EmailAndPasswordForm = ({submitLabel, submit, loading}: EmailAndPasswordFormProps) => {
    const formFields: Field[] = [
        {
            fieldName: "email",
            fieldLabel: "Email",
            fieldRequired: true,
            fieldType: "email",
        },
        {
            fieldName: "password",
            fieldLabel: "Password",
            fieldRequired: true,
            fieldType: "password",
            fieldMinLength: 8,
            fieldHelperText: "Must be at least 8 characters",
        },
    ];

    function handleSubmit({email, password}: FieldValues) {
        if (!email || !password) return;
        submit(email, password);
    }

    return (
        <Form
            fields={formFields}
            submitButtonLabel={submitLabel}
            submitButtonLoading={loading}
            handleFormSubmit={handleSubmit}
        />
    );
}

export default EmailAndPasswordForm;