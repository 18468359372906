import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Verified from "../../assets/verified.svg";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LinkIcon from "@mui/icons-material/Link";
import React, { useEffect } from "react";
import { Form, Field } from "../../shared/Form";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {
  useGetV1ExtensionsQuery,
  usePutV1ExtensionsMutation,
} from "../../state/layerApi";
import { useDownloadObjectQuery } from "../../state/emptyApi";
import PageTemplate from "../../shared/PageTemplate";
import StoreIcon from "@mui/icons-material/Store";
import EmbeddableLinks from "./EmbeddableLinks";
import copy from "copy-to-clipboard";
import { useDispatch } from "react-redux";
import { setSnackbarOpen } from "../../state/snackbarSlice";

// --------------- Types ---------------
type FieldValues = {
  marketplaceName?: string;
  marketplaceDisplayName?: string;
  marketplaceDescription?: string;
  marketplaceIcon?: string;
  marketplaceREADME?: string;
  participantName?: string;
  participantDescription?: string;
};

// --------------- Helper Functions ---------------
function fieldValues() {
  return {
    marketplaceName: "",
    marketplaceDisplayName: "",
    marketplaceDescription: "",
    marketplaceREADME: "",
    marketplaceIcon: "",
    participantName: "",
    participantDescription: "",
  };
}

// --------------- Components ---------------
const MarketplaceListing = () => {
  const dispatch = useDispatch();

  const { data: extension, refetch: refetchExtension } =
    useGetV1ExtensionsQuery();
  const [putExtension] = usePutV1ExtensionsMutation();
  const { data: readme, refetch: refetchREADME } =
    useDownloadObjectQuery("README.md");
  const { data: logo, refetch: refetchLogo } =
    useDownloadObjectQuery("logo.png");

  const [tabValue, setTabValue] = React.useState("Overview");
  const [submitButtonLoading, setSubmitButtonLoading] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [marketplaceListingDetails, setMarketplaceListingDetails] =
    React.useState<FieldValues>(fieldValues());

  const formFields: Field[] = [
    {
      fieldName: "marketplaceIcon",
      fieldLabel: "Extension Icon",
      fieldType: "file",
      fieldRequired: false,
      fieldAcceptedFileTypes: ["png", "jpg"],
      fieldImageMinSize: { width: 128, height: 128 },
      fieldImageCrop: {
        aspectRatio: 1,
        minSize: { width: 128, height: 128 },
        rescaleImageWidth: 256,
      },
    },
    {
      fieldName: "marketplaceDisplayName",
      fieldLabel: "Extension Display Name",
      fieldType: "text",
      fieldDefaultValue: marketplaceListingDetails.marketplaceDisplayName,
      fieldRequired: true,
      fieldHelperText:
        "The display name for the extension used in the Marketplace.",
    },
    {
      fieldName: "marketplaceDescription",
      fieldLabel: "Extension Description",
      fieldType: "textarea",
      fieldDefaultValue: marketplaceListingDetails.marketplaceDescription,
      fieldRequired: true,
      fieldStartingRows: 2,
    },
    {
      fieldName: "marketplaceREADME",
      fieldLabel: "Extension Overview",
      fieldType: "markdown",
      fieldDefaultValue: marketplaceListingDetails.marketplaceREADME,
      fieldRequired: true,
    },
    {
      fieldName: "participantName",
      fieldLabel: "Copilot Chat Participant Name",
      fieldType: "text",
      fieldDefaultValue: marketplaceListingDetails.participantName,
      fieldHelperText:
        "This is how your participant will be invoked, prefixed by @ in Copilot chat.",
      fieldRequired: true,
      fieldValidation: (name: string) => {
        if (name.includes(" ")) {
          return "Participant name cannot contain spaces.";
        }
      },
    },
    {
      fieldName: "participantDescription",
      fieldLabel: "Copilot Chat Participant Description",
      fieldType: "text",
      fieldDefaultValue: marketplaceListingDetails.participantDescription,
      fieldHelperText:
        "This will appear as the placeholder text in Copilot chat while your participant is invoked.",
      fieldRequired: true,
    },
  ];

  async function handleFormSubmit(values: FieldValues) {
    setMarketplaceListingDetails(values);
    setSubmitButtonLoading(true);

    // Create a new FormData instance
    let formData = new FormData();

    // Append fields to formData (required by form)
    formData.append("description", values.marketplaceDescription!);
    formData.append("display_name", values.marketplaceDisplayName!);
    formData.append("participant_name", values.participantName!);
    formData.append("participant_description", values.participantDescription!);

    let readmeBlob = new Blob([values.marketplaceREADME!], {
      type: "text/markdown",
    });
    let readmeFile = new File([readmeBlob], "readme.md");
    formData.append("readme", readmeFile);

    // Append file to formData
    if (values.marketplaceIcon) {
      formData.append("logo", values.marketplaceIcon);
    }

    // Call the mutation
    putExtension({
      // @ts-ignore
      extensionUpdate: formData,
    }).then(() => {
      refetchLogo();
      refetchREADME();
      setSubmitButtonLoading(false);
      setEditMode(false);
    });
  }

  useEffect(() => {
    let vals = fieldValues();

    if (extension) {
      vals.marketplaceName = extension.name;
      vals.marketplaceDisplayName = extension.display_name;
      vals.marketplaceDescription = extension.description;
      vals.participantName = extension.participant_name;
      vals.participantDescription = extension.participant_description;
    }

    if (readme) {
      vals.marketplaceREADME = (readme as any).data as string;
    }

    if (logo) {
      vals.marketplaceIcon = (logo as any).data as string;
    }

    setMarketplaceListingDetails(vals);
  }, [extension, readme, logo]);

  useEffect(() => {
    if (extension) {
      refetchExtension();
    }
  }, []);

  return (
    <PageTemplate
      name={"Marketplace Listing"}
      description={
        "Adjust how your Integration Expert extension will show up in VSCode’s marketplace."
      }
      icon={<StoreIcon />}
    >
      <Accordion
        elevation={0}
        sx={{
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              py: 0.5,
            }}
          >
            <LinkIcon />
            <Typography variant="h4">Embeddable Links</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <EmbeddableLinks />
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          p: 3,
          textAlign: "left",
          border: 1,
          borderColor: "divider",
          borderRadius: 1,
        }}
      >
        {!editMode && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Box
              sx={{
                px: 2,
                py: 0.5,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}>
                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                  URL:
                </Typography>
                <Typography variant="body2">
                  {extension?.marketplace_url}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <IconButton
                  onClick={() => {
                    copy(extension?.marketplace_url ?? "");
                    dispatch(
                      setSnackbarOpen({
                        open: true,
                        message: "Marketplace URL Copied to Clipboard",
                      }),
                    );
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
                <a
                  href={extension?.marketplace_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton>
                    <OpenInNewIcon />
                  </IconButton>
                </a>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                p: 4,
                bgcolor: "grey.100",
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  alignItems: "start",
                }}
              >
                <Box sx={{ width: "128px", height: "128px", bgcolor: "black" }}>
                  <Box>
                    <img
                      src={marketplaceListingDetails.marketplaceIcon}
                      alt="Plaid Logo"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  gap: 2,
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    gap: 1,
                  }}
                >
                  <Typography variant="h3">
                    {marketplaceListingDetails.marketplaceDisplayName}
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "12px" }}
                  >
                    <Typography sx={{ fontWeight: "600" }}>Layer</Typography>
                    <Box
                      sx={{ display: "flex", flexDirection: "row", gap: "4px" }}
                    >
                      <img src={Verified} alt="Verified" />
                      <Typography sx={{ color: "#0096D7" }}>
                        buildwithlayer.com
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography>
                  {marketplaceListingDetails.marketplaceDescription}
                </Typography>
                <Box
                  sx={{
                    px: 4,
                    py: "4px",
                    bgcolor: "grey.200",
                    color: "#A6A6A6",
                  }}
                >
                  <Typography variant="button">Install</Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                onClick={() => {
                  setEditMode(true);
                }}
                sx={{
                  alignSelf: "start",
                  px: 4,
                  py: 1,
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <EditIcon />
                Edit
              </Button>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <Tabs
                value={tabValue}
                onChange={(_, newValue) => setTabValue(newValue)}
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Tab value="Overview" label="Overview" />
                <Tab value="Version History" label="Version History" />
                <Tab value="Q&A" label="Q&A" />
                <Tab value="Rating & Review" label="Rating & Review" />
                <Tab
                  value="Copilot Chat Participant"
                  label="Copilot Chat Participant"
                />
              </Tabs>
              {tabValue === "Overview" && (
                <ReactMarkdown remarkPlugins={[gfm]} className="markdown">
                  {marketplaceListingDetails.marketplaceREADME}
                </ReactMarkdown>
              )}
              {tabValue === "Version History" && (
                <Typography>Version History not yet implemented.</Typography>
              )}
              {tabValue === "Q&A" && (
                <Typography>Q&A not yet implemented.</Typography>
              )}
              {tabValue === "Rating & Review" && (
                <Typography>Rating & Review not yet implemented.</Typography>
              )}
              {tabValue === "Copilot Chat Participant" && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography>
                    This is how your participant will appear when invoked in
                    Copilot chat:
                  </Typography>
                  {marketplaceListingDetails.participantDescription &&
                    marketplaceListingDetails.participantName && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: 1,
                          p: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "500",
                              px: 0.5,
                              py: 0.1,
                              color: "#2261B8",
                              bgcolor: "#D5ECFF",
                              borderRadius: 1,
                            }}
                          >
                            @{marketplaceListingDetails.participantName}
                          </Typography>
                          <Typography sx={{ color: "grey.500" }}>
                            {marketplaceListingDetails.participantDescription}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                </Box>
              )}
            </Box>
          </Box>
        )}
        {editMode && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" sx={{ flexGrow: 1 }}>
                Edit Marketplace Listing
              </Typography>
              <IconButton onClick={() => setEditMode(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Form
              fields={formFields}
              handleFormSubmit={handleFormSubmit}
              submitButtonLabel="Save Changes"
              submitButtonLoading={submitButtonLoading}
            />
          </Box>
        )}
      </Box>
    </PageTemplate>
  );
};

export default MarketplaceListing;
