import {GridRowId} from "@mui/x-data-grid";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

interface EditSourceModalOpenState {
    open: boolean;
    selectedRowId: GridRowId | null;
}

interface EditSourceModalOpenPayload {
    open: boolean;
    selectedRowId: GridRowId | null;
}

const initialState: EditSourceModalOpenState = {open: false, selectedRowId: null};

const editSourceModalOpenSlice = createSlice({
    name: "editSourceModalOpen",
    initialState,
    reducers: {
        setEditSourceModalOpen(state, action: PayloadAction<EditSourceModalOpenPayload>) {
            state.open = action.payload.open;
            state.selectedRowId = action.payload.selectedRowId;
        },
    },
});

export const {setEditSourceModalOpen} = editSourceModalOpenSlice.actions;
export default editSourceModalOpenSlice.reducer;