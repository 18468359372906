import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import {auth} from "../clients/firebase";

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const firebaseAuth = await auth.currentUser?.getIdToken() || "";
  return fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LAYER_URL || "",
    prepareHeaders: (headers: Headers) => {
      headers.set("Firebase-Auth", firebaseAuth);
      return headers;
    },
  })(args, api, extraOptions);
};

export const emptyApi = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    downloadObject: builder.query({
      queryFn: async (specialObjectName, _, __, baseQuery) => {
        const response = await baseQuery({
          url: `v1/extensions/download/${specialObjectName}`,
          responseHandler: async (response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }

            // Check the content type of the response to determine how to handle it
            const extension = specialObjectName.split(".").pop();
            if (
              extension === "jpg" ||
              extension === "png" ||
              extension === "gif"
            ) {
              // If it's an image, create a Blob URL
              const blob = await response.blob();
              const url = URL.createObjectURL(blob);

              return { data: url };
            } else if (extension === "txt" || extension === "md") {
              // If it's a text file, decode it
              let td = new TextDecoder("utf-8");
              let data = td.decode(await response.arrayBuffer());
              return { data };
            } else {
              throw new Error("Unsupported file type");
            }
          },
        });
        let data = response?.data;
        return { data: data };
      },
    }),
  }),
});

export const { useDownloadObjectQuery } = emptyApi;
