import BarChartIcon from "@mui/icons-material/BarChart";
import PageTemplate from "../../../shared/PageTemplate";
import { Box, Typography } from "@mui/material";
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridOverlay,
} from "@mui/x-data-grid";
import AnalyticsGridToolbar from "./AnalyticsGridToolbar";
import { formatDateTime } from "../../../utils/dateTime";
import {
  GetV1ChatMessageLogApiResponse,
  MessageLog,
  useGetV1ChatMessageLogQuery,
} from "../../../state/layerApi";
import { useMemo } from "react";

function AnalyticsDataGrid({
  columns,
  rows,
  dataGridPropOverrides,
}: {
  columns: GridColDef[];
  rows: any[];
  dataGridPropOverrides?: Omit<Partial<DataGridProps>, "columns" | "rows">;
}) {
  return (
    <DataGrid
      columns={columns}
      rows={rows}
      pageSizeOptions={[10, 25, 50, 100]}
      columnVisibilityModel={{
        id: false,
      }}
      slots={{
        toolbar: () => <AnalyticsGridToolbar />,
        noRowsOverlay: () => (
          <GridOverlay>
            <Typography variant="body2" color="GrayText">
              No Data Available
            </Typography>
          </GridOverlay>
        ),
        noResultsOverlay: () => (
          <GridOverlay>
            <Typography variant="body2" color="GrayText">
              No Results
            </Typography>
          </GridOverlay>
        ),
      }}
      {...dataGridPropOverrides}
    />
  );
}
const Analytics = () => {
  const { data: queries } = useGetV1ChatMessageLogQuery();

  const queryRows = useMemo(() => {
    return queries?.map((query) => {
      return {
        id: query.id,
        userIp: query.ip,
        query: query.message,
        timestamp: new Date(query.updated_at!),
      };
    });
  }, [queries]);

  const userRows = useMemo(() => {
    const userCounts = queries?.reduce(
      (
        acc: Record<string, { count: number; lastActive: Date }>,
        query: MessageLog,
      ) => {
        if (query.ip in acc) {
          acc[query.ip].count += 1;
          if (new Date(query.updated_at!) > acc[query.ip].lastActive) {
            acc[query.ip].lastActive = new Date(query.updated_at!);
          }
        } else {
          acc[query.ip] = { count: 1, lastActive: new Date(query.updated_at!) };
        }
        return acc;
      },
      {},
    );

    if (!userCounts) return [];

    const users = Object.entries(userCounts).map(([ip, data]) => ({
      userIp: ip,
      messageCount: data.count,
      lastActive: data.lastActive,
      id: ip,
    }));
    return users;
  }, [queries]);

  const queriesTableColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 200,
      type: "number",
    },
    { field: "query", headerName: "Query", flex: 1, type: "string" },
    {
      field: "userIp",
      headerName: "User IP",
      width: 200,
      type: "string",
    },
    {
      field: "timestamp",
      headerName: "Timestamp",
      width: 200,
      type: "dateTime",
      renderCell: (params) => {
        return formatDateTime(params.value);
      },
    },
  ];

  const usersTableColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 200,
      type: "number",
    },
    {
      field: "userIp",
      headerName: "User IP",
      flex: 1,
      type: "string",
    },
    {
      field: "messageCount",
      headerName: "Message Count",
      width: 150,
      type: "number",
    },
    {
      field: "lastActive",
      headerName: "Last Active",
      width: 200,
      type: "dateTime",
      renderCell: (params) => {
        if (!params.value) return "";
        return formatDateTime(params.value);
      },
    },
  ];

  return (
    <PageTemplate
      name="Analytics"
      description="Get analytics on how developers are using your Integration Expert."
      icon={<BarChartIcon />}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="h6">Queries</Typography>
          <AnalyticsDataGrid
            columns={queriesTableColumns}
            rows={queryRows || []}
            dataGridPropOverrides={{
              initialState: {
                sorting: { sortModel: [{ field: "timestamp", sort: "desc" }] },
              },
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="h6">Users</Typography>
          <AnalyticsDataGrid
            columns={usersTableColumns}
            rows={userRows || []}
            dataGridPropOverrides={{
              initialState: {
                sorting: {
                  sortModel: [{ field: "last-active", sort: "desc" }],
                },
              },
            }}
          />
        </Box>
      </Box>
    </PageTemplate>
  );
};

export default Analytics;
