import React, {ReactElement, ReactNode} from "react";
import {
    Box,
    Typography,
    Container,
} from "@mui/material";
import AddSourceModal from "../components/dashboard/sources/table/AddSourceModal";

export interface IPageTemplate {
    name: string;
    description: string;
    icon: ReactElement;
    children: ReactNode;
}

const PageTemplate = ({name, description, icon, children}: IPageTemplate) => {
    return (
        <>
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        py: 4,
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                        }}
                    >
                        {React.cloneElement(icon, {sx: {fontSize: "32px"}})}
                        <Typography variant="h2">{name}</Typography>
                    </Box>
                    <Typography variant="subtitle1" sx={{mb: 2}}>
                        {description}
                    </Typography>
                    {children}
                </Box>
            </Container>
            <AddSourceModal/>
        </>
    );
};

export default PageTemplate;
