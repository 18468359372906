import * as React from "react";
import { useLocation } from "react-router-dom";
import Sources from "./sources/Sources";
import MarketplaceListing from "./MarketplaceListing";
import Analytics from "./analytics/Analytics";

const Dashboard = () => {
  const location = useLocation();
  const [page, setPage] = React.useState<
    "sources" | "marketplace-listing" | "analytics"
  >(
    location.pathname.startsWith("/marketplace-listing")
      ? "marketplace-listing"
      : "sources",
  );

  React.useEffect(() => {
    switch (location.pathname) {
      case "/marketplace-listing":
        setPage("marketplace-listing");
        break;
      case "/analytics":
        setPage("analytics");
        break;
      default:
        setPage("sources");
        break;
    }
  }, [location, setPage]);

  React.useEffect(() => {
    document.title =
      page === "sources" ? "Layer - Sources" : "Layer - Marketplace Listing";
  }, [page]);

  switch (page) {
    case "sources":
      return <Sources />;
    case "marketplace-listing":
      return <MarketplaceListing />;
    case "analytics":
      return <Analytics />;
    default:
      return <Sources />;
  }
};

export default Dashboard;
