import { Box, IconButton, Modal, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface IModalDialog {
  label: string;
  modalOpen: boolean;
  handleClose: () => void;
  closeOnBackdropClick?: boolean;
  hasDefaultWidth?: boolean;
  defaultWidth?: number;
  hasBackButton?: boolean;
  handleBackButton?: () => void;
  children?: ReactNode;
}

const ModalDialog = ({
  label,
  modalOpen,
  handleClose,
  closeOnBackdropClick = false,
  hasDefaultWidth = true,
  defaultWidth = 400,
  hasBackButton = false,
  handleBackButton,
  children,
}: IModalDialog) => {
  const handleCloseModal = (event: {}, reason: string) => {
    if (!closeOnBackdropClick && reason === "backdropClick") return;
    handleClose();
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        slotProps={{ backdrop: { sx: { bgcolor: "rgba(0,0,0,0.7)" } } }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            p: 2,
            width: hasDefaultWidth ? defaultWidth : "auto",
            maxHeight: "90vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              {hasBackButton && (
                <IconButton onClick={handleBackButton}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Typography variant="h4">{label}</Typography>
            </Box>
            <IconButton onClick={() => handleCloseModal({}, "closeClick")}>
              <CloseIcon />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Modal>
    </>
  );
};

export default ModalDialog;
