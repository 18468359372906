import {Box, Button, Typography} from "@mui/material";
import * as React from "react";
import GoogleLogo from "../../assets/google-logo.svg";
import LayerLogoDark from "../../assets/LayerLogoDark.svg";
import DashboardPreview from "../../assets/DashboardPreview.png";
import {createUserWithEmailAndPassword, signInWithPopup} from "firebase/auth";
import {auth, provider} from "../../clients/firebase";
import {
    usePostV1CustomersFirebaseMutation,
} from "../../state/layerApi";
import CompanyNameForm from "./CompanyNameForm";
import EmailAndPasswordForm from "./EmailAndPasswordForm";
import {LoadingButton} from "@mui/lab";
import {beforeAuthStateChanged, User} from "firebase/auth";
import {useSnackbarContext} from "../../contexts/SnackbarContext";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const Signup = () => {
    const navigate = useNavigate();
    const {addMessage} = useSnackbarContext();
    const [postCustomer] = usePostV1CustomersFirebaseMutation();
    const [companyName, setCompanyName] = React.useState<string | undefined>(
        undefined,
    );
    const [isLoading, setLoading] = React.useState(false);

    useEffect(() => {
        const cleanup = beforeAuthStateChanged(auth, (user: User | null) => {
            if (user === null) return;
            return user.getIdToken()
                .then((token: string) => {
                    return postCustomer({
                        firebaseCustomerCreate:  {
                            company_name: companyName,
                            firebase_token: token,
                        },
                    });
                })
                .then((resp) => {
                    if ("error" in resp) {
                        throw new Error(resp.error as string);
                    }
                });
        });

        return () => cleanup();
    }, [companyName, postCustomer]);

    async function handleSignUp(email: string, password: string) {
        if (!companyName) return;
        setLoading(true);
        createUserWithEmailAndPassword(auth, email, password)
            .catch((err) => {
                console.error(err);
                if (err.code === "auth/login-blocked") {
                    addMessage("Email already in use! Please log in instead.", "error", 60);
                }
            })
            .finally(() => setLoading(false));
    }

    async function handleGoogleSignUp() {
        if (!companyName) return;
        setLoading(true);
        signInWithPopup(auth, provider)
            .catch((err) => {
                console.error(err);
                if (err.code === "auth/login-blocked") {
                    addMessage("Email already in use! Please log in instead.", "error", 60);
                }
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    position: "absolute",
                    top: "32px",
                    left: "32px",
                }}
            >
                <img src={LayerLogoDark} alt={"Layer Logo"} style={{width: "40px"}}/>
                <Box sx={{display: "flex", flexDirection: "column", gap: "2px"}}>
                    <Typography variant="h1" sx={{fontSize: "1.1rem"}}>
                        Layer
                    </Typography>
                    <Typography variant="h1" sx={{fontSize: "1rem", fontWeight: "300"}}>
                        Integration Expert
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100vw",
                    height: "100vh",
                    alignItems: "stretch",
                }}
            >
                <Box
                    sx={{
                        minWidth: "60%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "500px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "stretch",
                            gap: 3,
                        }}
                    >
                        <Typography variant="h2">Sign Up</Typography>
                        <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
                            {!companyName && (
                                <CompanyNameForm setCompanyName={setCompanyName}/>
                            )}
                            {companyName && (
                                <>
                                    <EmailAndPasswordForm
                                        submitLabel={"Sign Up"}
                                        loading={isLoading}
                                        submit={handleSignUp}
                                    />
                                    <LoadingButton
                                        variant="contained"
                                        onClick={handleGoogleSignUp}
                                        loading={isLoading}
                                        sx={{
                                            elevation: 0,
                                            bgcolor: "white",
                                            color: "#333333",
                                            border: 1,
                                            borderColor: "divider",
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: 2,
                                            ":hover": {
                                                bgcolor: "#F3F3F3",
                                            },
                                        }}
                                    >
                                        <img
                                            src={GoogleLogo}
                                            alt="Google Logo"
                                            style={{width: "24px", height: "24px"}}
                                        />
                                        Sign up with Google
                                    </LoadingButton>
                                </>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1,
                                flexGrow: 1,
                            }}
                        >
                            <Typography sx={{textAlign: "center", color: "#757575"}}>
                                Already have an account?
                            </Typography>
                            <Button
                                variant="text"
                                onClick={() => navigate("/login")}
                                sx={{py: 0, px: "2px", minWidth: 0}}
                            >
                                Log in
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        pl: "5%",
                        gap: 8,
                        justifyContent: "end",
                        alignItems: "start",
                        bgcolor: "#7C7799",
                        color: "white",
                        boxSizing: "border-box",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            pr: "5%",
                        }}
                    >
                        <Typography variant="h2">
                            Make your API easier to integrate
                        </Typography>
                        <Typography>
                            Just upload your documentation and reference materials and we’ll
                            deploy your Integration Expert to VS Code, Github Copilot, and
                            more.
                        </Typography>
                    </Box>
                    <img
                        src={DashboardPreview}
                        alt="Layer Management Platform Dashboard"
                        style={{
                            flexGrow: 1,
                            width: "100%",
                            maxHeight: "60%",
                            objectFit: "cover",
                            objectPosition: "top left",
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default Signup;
