import React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ChatIcon from "@mui/icons-material/Chat";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RefreshIcon from "@mui/icons-material/Refresh";
import SendIcon from "@mui/icons-material/Send";
import {
    Alert,
    Box,
    Button,
    IconButton,
    LinearProgress,
    Typography,
} from "@mui/material";
import {
    Citation,
    PostV1ChatUserMessagesApiArg,
    useGetV1CustomersQuery,
    usePostV1ChatUserMessagesMutation,
} from "../../../state/layerApi";
import {useForm} from "react-hook-form";
import {FormContainer, TextFieldElement} from "react-hook-form-mui";

interface citation {
    url: string;
    title: string;
}

interface iChatMessage {
    content: string;
    role: string;
    citations?: citation[];
    loading?: boolean;
}

interface iMessageInput {
    handleSend: (message: string) => void;
    loading: boolean;
}

function ChatMessage({content, role, citations, loading}: iChatMessage) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                alignItems: "center",
                bgcolor: role === "user" ? "primary.200" : "grey.200",
                p: 2,
                borderRadius: 1,
                textAlign: "left",
            }}
        >
            <Typography
                sx={{fontSize: "1.05rem", fontWeight: "600", width: "100%"}}
            >
                {role === "user" ? "You" : "Integration Expert"}
            </Typography>
            {loading ? (
                <LinearProgress sx={{width: "100%", mt: 2}}/>
            ) : (
                <Box sx={{width: "100%", textAlign: "left"}}>
                    <ReactMarkdown remarkPlugins={[gfm]} className="markdown">
                        {content}
                    </ReactMarkdown>
                </Box>
            )}
            {citations && citations.length > 0 && (
                <>
                    <Typography sx={{mt: 2, fontWeight: "500", width: "100%"}}>
                        Citations:
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            flexWrap: "wrap",
                            width: "100%",
                        }}
                    >
                        {citations.map((citation) => (
                            <a href={citation.url} rel="noreferrer" target="none">
                                <Box
                                    sx={{
                                        borderRadius: 1,
                                        bgcolor: "primary.main",
                                        color: "white",
                                        p: 1,
                                        "&:hover": {bgcolor: "primary.dark", cursor: "pointer"},
                                    }}
                                >
                                    <Typography>{citation.title}</Typography>
                                </Box>
                            </a>
                        ))}
                    </Box>
                </>
            )}
        </Box>
    );
}

function MessageInput({handleSend, loading}: iMessageInput) {
    const formContext = useForm();
    const {handleSubmit, getValues, resetField, watch} = formContext;

    const [sendDisabled, setSendDisabled] = React.useState(true);

    function handleSuccess() {
        const values = getValues();
        resetField("message");
        setSendDisabled(true);
        handleSend(values.message);
    }

    React.useEffect(() => {
        const message = watch("message");
        if (!loading && message) {
            message.length > 0 ? setSendDisabled(false) : setSendDisabled(true);
        }
    }, [watch, loading]);

    return (
        <FormContainer
            formContext={formContext}
            handleSubmit={handleSubmit(handleSuccess)}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                }}
            >
                <TextFieldElement
                    name="message"
                    label="Message"
                    sx={{flexGrow: 1}}
                    onChange={(e) => {
                        if (!loading) {
                            e.target.value.length > 0
                                ? setSendDisabled(false)
                                : setSendDisabled(true);
                        }
                    }}
                />
                <Button
                    variant="outlined"
                    disabled={sendDisabled}
                    type="submit"
                    sx={{width: "56px", height: "56px", p: 0}}
                >
                    <SendIcon/>
                </Button>
            </Box>
        </FormContainer>
    );
}

const ChatPreview = () => {
    const {data: customer} = useGetV1CustomersQuery();

    const [chatCancelled, setChatCancelled] = React.useState(false);

    const initialMessage = {
        content:
            "Hey! I see you need help integrating " +
            customer?.company_name +
            ". Let me know if you have any questions!",
        role: "assistant",
    };

    const [messages, setMessages] = React.useState<iChatMessage[]>([
        initialMessage,
    ]);

    const [showDisclaimer, setShowDisclaimer] = React.useState(true);

    const chatBoxRef = React.useRef<HTMLDivElement>(null);

    function resetChat() {
        setChatCancelled(true);
        setMessages([initialMessage]);
        setShowDisclaimer(true);
    }

    async function animateStreamingChatResponse(text: string) {
        return new Promise((resolve) => {
            const textArray = text.split(" ");
            let index = 0;
            let newText = "";
            const interval = setInterval(
                () => {
                    newText += textArray[index];
                    if (index < textArray.length - 1) {
                        newText += " ";
                    }
                    setMessages((prevMessages) => [
                        ...prevMessages.slice(0, -1),
                        {content: newText, role: "assistant"},
                    ]);
                    index++;
                    if (index === textArray.length) {
                        clearInterval(interval);
                        resolve(null);
                    }
                },
                Math.random() * 50 + 50,
            );
        });
    }

    function handleSendMessage(message: string) {
        const messagesWithoutCitations = messages.map((message) => {
            return {content: message.content, role: message.role};
        });
        setMessages((prevMessages) => [
            ...prevMessages,
            {content: message, role: "user"},
        ]);
        setTimeout(
            () => {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {content: "", role: "assistant", loading: true},
                ]);
            },
            Math.random() * 200 + 500,
        );
        postMessage({
            chatCompletionRequest: {
                query: message,
                history: messagesWithoutCitations,
            },
        } as PostV1ChatUserMessagesApiArg).then((response) => {
            if (chatCancelled) {
                setChatCancelled(false);
                return;
            } else {
                if ("data" in response) {
                    animateStreamingChatResponse(response.data.message).then(() => {
                        setMessages((prevMessages) => [
                            ...prevMessages.slice(0, -1),
                            {
                                content: response.data.message,
                                role: "assistant",
                                citations: response.data.citations.map((citation: Citation) => {
                                    return { url: citation.url, title: citation.name };
                                }),
                            },
                        ]);
                    });
                }
            }
        });
    }

    React.useEffect(() => {
        const chatBox = chatBoxRef.current;
        if (chatBox) {
            chatBox.scrollTop = chatBox.scrollHeight;
        }
    }, [messages]);

    const [postMessage, {isLoading: isResponseLoading}] =
        usePostV1ChatUserMessagesMutation();

    return (
        <Accordion
            elevation={0}
            sx={{
                borderRadius: 1,
                border: "1px solid",
                borderColor: "divider",
            }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        mr: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            alignItems: "center",
                            py: 0.5,
                        }}
                    >
                        <ChatIcon/>
                        <Typography variant="h4">Chat Preview</Typography>
                    </Box>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            resetChat();
                        }}
                    >
                        <RefreshIcon/>
                    </IconButton>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{bgcolor: "background.paper", p: 0}}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                        pt: 0.5,
                        justifyContent: "space-between",
                        gap: 2,
                        height: "400px",
                    }}
                >
                    <Box
                        ref={chatBoxRef}
                        sx={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            maxHeight: "100%",
                            overflowY: "auto",
                        }}
                    >
                        {showDisclaimer && (
                            <Alert severity="info" onClose={() => setShowDisclaimer(false)}>
                                Please note, the chat experience in this app may function
                                differently than in Github Copilot.
                            </Alert>
                        )}
                        {messages.map((message, idx) => {
                            return (
                                <ChatMessage
                                    key={idx}
                                    content={message.content}
                                    role={message.role}
                                    citations={message.citations}
                                    loading={message.loading}
                                />
                            );
                        })}
                    </Box>
                    <MessageInput
                        handleSend={handleSendMessage}
                        loading={isResponseLoading}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ChatPreview;
