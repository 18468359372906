import { Box, Button } from "@mui/material";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import React from "react";

const AnalyticsGridToolbar = () => {
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          display: "flex",
          gap: 4,
          alignItems: "center",
          p: "12px",
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
        }}
      >
        <GridToolbarQuickFilter sx={{ flexGrow: 1 }} variant="filled" />
      </Box>
    </GridToolbarContainer>
  );
};

export default AnalyticsGridToolbar;
