import { LoadingButton } from "@mui/lab";
import PublishIcon from "@mui/icons-material/Publish";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { setDarkMode } from "../../state/themeSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import {
  ExtensionTemplateList,
  useGetV1CustomersQuery,
  useGetV1ExtensionsQuery,
  useGetV1ExtensionsTemplatesQuery,
  usePostV1ExtensionsPublishMutation,
} from "../../state/layerApi";
import { useSnackbarContext } from "../../contexts/SnackbarContext";
import { signOut } from "firebase/auth";
import { auth } from "../../clients/firebase";

const Footer = ({ expanded }: { expanded: boolean }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { data: customer } = useGetV1CustomersQuery();
  const { data: extension, refetch: refetchExtension } =
    useGetV1ExtensionsQuery();
  const [publishExtension, { isLoading }] =
    usePostV1ExtensionsPublishMutation();
  const { addMessage } = useSnackbarContext();

  const { data: templates } = useGetV1ExtensionsTemplatesQuery();

  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const [selectedTemplate, setSelectedTemplate] =
    React.useState("codewithlayer.zip");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2, gap: 2, pb: 3 }}>
      <Select
        value={selectedTemplate}
        onChange={(event) => setSelectedTemplate(event.target.value)}
        fullWidth
      >
        {templates?.template_names?.map((template) => (
          <MenuItem key={template} value={template}>
            {template}
          </MenuItem>
        ))}
      </Select>
      <LoadingButton
        variant="contained"
        disabled={!extension?.pending_publish}
        loading={isLoading}
        fullWidth
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          ...(!expanded && {
            justifyContent: "center",
            minWidth: 0,
            width: "100%",
          }),
        }}
        onClick={() => {
          publishExtension({
            extensionPublish: { template_name: selectedTemplate },
          })
            .then((e) => {
              if (e.hasOwnProperty("error")) {
                addMessage("Failed to publish extension.", "error", 60);
              } else {
                refetchExtension();
                addMessage("Published extension!", "success", 60);
              }
            })
            .catch(() =>
              addMessage("Failed to publish extension.", "error", 60),
            );
        }}
      >
        <PublishIcon />
        {expanded && "Publish Changes"}
      </LoadingButton>
      {expanded && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
            px: 2,
            borderRadius: 1,
            border: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography>Dark Mode:</Typography>
          <Switch
            checked={darkMode}
            onChange={() => {
              dispatch(setDarkMode(!darkMode));
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
          overflow: "hidden",
          px: 2,
          py: 1,
          ...(!expanded && {
            justifyContent: "center",
            p: 1,
          }),
        }}
      >
        {expanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              textOverlow: "ellipsis",
            }}
          >
            <Typography noWrap>{customer?.company_name}</Typography>
            <Typography variant="body2" noWrap>
              {customer?.email}
            </Typography>
          </Box>
        )}
        <Tooltip
          title="Log out"
          placement="top"
          disableInteractive
          enterDelay={500}
        >
          <IconButton
            onClick={() => {
              signOut(auth).catch((err) => {
                console.error(err);
              });
            }}
          >
            <LogoutIcon sx={{ color: "#7B65FF" }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Footer;
